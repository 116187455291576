<template>
  <layout content-class="pa-0">
    <template #header>
      Change application
    </template>
    <template>
      <v-list>
        <v-list-item
          v-for="item in appList"
          :key="item.id"
          @click="handleClick(item)"
        >
          <v-list-item-avatar>
            <img
              class="apps__item-image"
              :src="`/img/${item.icon}`"
              :alt="item.name"
            />
          </v-list-item-avatar>
          <v-list-item-title v-text="item.name" />
        </v-list-item>
      </v-list>
    </template>

    <template #footer>
      <v-spacer />
      <v-btn color="primary" text @click.stop="$emit('close')">
        Close
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { useApp } from '@/modules/account/useApp';
import { onMounted } from '@vue/composition-api';

export default {
  name: 'AppSelectMobile',
  components: {
    Layout
  },
  setup(props, { emit }) {
    const { appList, goToApp, loadApp } = useApp();

    const handleClick = item => {
      goToApp(item);
      emit('close');
    };

    onMounted(() => {
      loadApp();
    });

    return {
      appList,
      handleClick
    };
  }
};
</script>

<style lang="sass"></style>
