import { useAuth } from '@/modules/auth/api';
import { ref } from '@vue/composition-api';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import schemaGql from '@/modules/auth/api/schemas.graphql';

export function useApp() {
  const variables = ref({ id: null, orderBy: 'NAME_DESC' });
  const { result, restart, load, loading, onResult } = useLazyQuery(
    schemaGql.userApps,
    variables,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only'
    }
  );
  const { user } = new useAuth();

  const token = window.localStorage.getItem('refreshToken');
  const tokenId = window.localStorage.getItem('tokenId');

  const appList = useResult(result, [], data => {
    return data?.user.userProfiles.map(item => item.object);
  });

  const goToApp = item => {
    window.open(
      `${item.url}/from-app/?token=${token}&tokenId=${tokenId}`,
      '_blank'
    );
  };

  const loadApp = () => {
    variables.value.id = user.value.id;
    if (!result.value) {
      load();
    } else {
      restart();
    }
  };

  return {
    appList,
    onResult,
    goToApp,
    loading,
    loadApp
  };
}
